import React from 'react'
import Swiper from 'swiper'
import ReactGA from 'react-ga'

class SwiperComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  books() {
    return this.props.data
  }

  componentDidMount() {
    var compParams = this.props.params
    const node = this
    let sliderUse = 0
    let sliderTitle = compParams.sliderTitle
    var params = {
      sliderTitle: sliderTitle,
      slidesPerView: 5,
      loop: false,
      freeModeMomentumRatio: 0.2,
      grabCursor: true,
      freeMode: true,
      pagination: {
        el: node.swipePager,
        hideOnClick: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: node.swipeNext,
        prevEl: node.swipePrev,
      },
      on: {
        slideChange: function() {
          if (sliderUse == 0) {
            sliderUse++
            ReactGA.ga(
              'send',
              'event',
              'Slider Use',
              `${sliderTitle} - slideChange`
            )
          }
        },
      },
      ...compParams,
    }
    this.mySwiper = new Swiper(node.swipeContainer, params)
    this.mySwiper.update()
  }

  render() {
    return (
      <div
        style={this.props.style}
        className={
          this.props.navigation > 0
            ? this.props.navigation == 1
              ? 'swiper-master swiper-full-width'
              : this.props.navigation == 2
              ? 'swiper-master swiper-inset'
              : 'swiper-master'
            : 'swiper-master swiper-inset'
        }>
        <div
          className={
            this.props.navigation == 1
              ? 'boxShadow swiper-container'
              : this.props.navigation == 2
              ? 'swiper-container'
              : 'swiper-container'
          }
          ref={e => (this.swipeContainer = e)}>
          {this.props.navigation == 1 ? (
            <div
              ref={e => (this.swipeGradLeft = e)}
              className="swiper-grad-left"></div>
          ) : null}
          {this.props.navigation == 1 ? (
            <div
              ref={e => (this.swipeGradRight = e)}
              className="swiper-grad-right"></div>
          ) : null}
          <div
            className={
              this.props.navigation >= 1
                ? 'swiper-wrapper'
                : 'swiper-wrapper swiper-wrapper-discover'
            }
            ref={e => (this.swipeWrapper = e)}>
            {this.props.children}
          </div>
          <div
            className="swiper-pagination"
            ref={e => (this.swipePager = e)}></div>
        </div>
        {this.props.navigation == 1 ? null : this.props.navigation == 2 ? (
          <div
            className="swiper-button-prev swiper-button-featured"
            ref={e => (this.swipePrev = e)}></div>
        ) : (
          <div
            className="swiper-button-prev"
            ref={e => (this.swipePrev = e)}></div>
        )}
        {this.props.navigation == 1 ? null : this.props.navigation == 2 ? (
          <div
            className="swiper-button-next swiper-button-featured"
            ref={e => (this.swipeNext = e)}></div>
        ) : (
          <div
            className="swiper-button-next"
            ref={e => (this.swipeNext = e)}></div>
        )}
      </div>
    )
  }
}

export default SwiperComponent
