import React from 'react'
import { Link } from 'gatsby'
import {
  titleCase,
  dynamicSort,
  getProperty,
  timeConverter,
  mapArray,
  mapArrayNoOxford,
} from '../helpers.js'
import Dotdotdot from 'react-dotdotdot'
import slider from '../css/modules/slideMenu.module.scss'
import bucket from '../css/modules/categoryWrapper.module.scss'
import SVG from 'react-inlinesvg'

import chevronRight from '../static/icons/chevronRight.svg'
import '../css/swiper.min.css'
import SwiperComponent from '../components/swiperComponent.js'

class BookCard extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  books() {
    return this.props.data
  }

  render() {
    return (
      <div className={`swiper-slide`}>
        <div className={bucket.background}>
          <div
            className={this.books().sortObject.image ? bucket.blurbg : null}
            style={{
              backgroundImage: this.books().sortObject.image
                ? `url(${this.books().sortObject.image})`
                : null,
            }}></div>
          <Link to={'/' + this.books().sortObject.gatsby_slug}>
            <img
              alt={`Cover of ${this.books().sortObject.title}`}
              style={{ display: this.state.loading ? 'none' : 'unset' }}
              ref={this.imageBackgrounder}
              src={this.books().sortObject.image}
            />
          </Link>
        </div>

        <div className={bucket.textbox}>
          <Link to={'/' + this.books().sortObject.gatsby_slug}>
            <Dotdotdot clamp={'60px'}>
              <h3>{this.books().sortObject.title}</h3>
            </Dotdotdot>
            <div className={bucket.bottomtext}>
              <Dotdotdot clamp={1}>
                <h4>{mapArray(this.books().sortObject.book_author)}</h4>
              </Dotdotdot>
            </div>
          </Link>
          <p>{this.books().sortObject.name}</p>
        </div>
      </div>
    )
  }
}

class CategoryWrapper extends React.Component {
  constructor() {
    super()
  }
  books() {
    return this.props.collection
  }

  createBookCards() {
    const bookCards = this.books().map((bookCard, i) => (
      <BookCard data={bookCard} index={i} key={bookCard.sortObject.id} />
    ))
    return bookCards
  }

  render() {
    var normalBreakpoints = {
      424: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      589: {
        slidesPerView: 3.5,
        spaceBetween: 10,
      },
      767: {
        slidesPerView: 4.5,
        spaceBetween: 10,
      },
      1023: {
        slidesPerView: 5.5,
        spaceBetween: 10,
      },
      99999: {
        slidesPerView: 6,
        spaceBetween: 10,
      },
    }

    var bookBreakpoints = {
      424: {
        slidesPerView: 1.5,
        spaceBetween: 10,
      },
      589: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      767: {
        slidesPerView: 3.5,
        spaceBetween: 10,
      },
      1023: {
        slidesPerView: 4.5,
        spaceBetween: 10,
      },
      99999: {
        slidesPerView: 5,
        spaceBetween: 10,
      },
    }

    return (
      <div
        className={
          this.props.bookpage
            ? [bucket.wrapper, bucket.noselect].join(' ')
            : null
        }>
        <div className={bucket.header}>
          {this.props.title ? (
            <h3 className={bucket.headerLeft}>{this.props.title}</h3>
          ) : (
            ''
          )}
          <p className={bucket.headerRight}>
            {this.props.slug ? <Link to={this.props.slug}>See all</Link> : null}
            {this.props.slug ? <SVG src={chevronRight} /> : null}
          </p>
        </div>
        <SwiperComponent
          bookpage={this.props.bookpage}
          params={{
            sliderTitle: this.props.title,
            watchOverflow: true,
            breakpoints: this.props.bookpage
              ? bookBreakpoints
              : normalBreakpoints,
          }}>
          {this.createBookCards()}
        </SwiperComponent>
      </div>
    )
  }
}

class CollectionBuilder extends React.Component {
  constructor() {
    super()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.data.allBookJson.edges.length ===
      this.props.data.allBookJson.edges.length
      ? false
      : true
  }

  books() {
    return this.props.data.allBookJson.edges
  }

  //getProperty and dynamicSort from helpers
  buildCollection(property, propertyValue = '') {
    var collectionArray = []
    for (var i = 0; i < this.books().length; i++) {
      if (property === 'genre') {
        if (
          mapArrayNoOxford(this.books()[i].node.genre).includes(propertyValue)
        ) {
          collectionArray.push({
            sortObject: this.books()[i].node,
            sortProperty: propertyValue,
          })
        }
      } else if (property === 'book_author') {
        if (
          mapArrayNoOxford(this.books()[i].node.book_author).includes(
            propertyValue
          )
        ) {
          collectionArray.push({
            sortObject: this.books()[i].node,
            sortProperty: propertyValue,
          })
        }
      } else if (property === 'image') {
        if (typeof this.books()[i].node.image !== 'object') {
          collectionArray.push({ sortObject: this.books()[i].node })
        }
      } else {
        collectionArray.push(getProperty(property, this.books()[i].node))
      }
    }

    if (propertyValue !== '') {
      collectionArray = collectionArray.filter(
        item => item.sortProperty == propertyValue
      )
    }
    // if (property === 'date') {
    //   collectionArray = collectionArray.sort(dynamicSort('date'))
    // } else {
    //   collectionArray = collectionArray.sort(function() {
    //     return 0.5 - Math.random()
    //   })
    // }

    // Randomization not working; just order by date
    collectionArray = collectionArray.sort(dynamicSort('date'))

    collectionArray = collectionArray.slice(0, 20)

    return collectionArray
  }

  categoryWrappers() {
    const categoryWrappers = this.props.wrappers.map((wrapper, i) => (
      <CategoryWrapper
        key={wrapper.title || wrapper.property || 'Recently Added'}
        bookpage={this.props.bookpage}
        title={wrapper.title || wrapper.property}
        slug={wrapper.slug ? `/${wrapper.slug}` : null}
        collection={this.buildCollection(wrapper.type, wrapper.property)}
      />
    ))
    return categoryWrappers
  }

  render() {
    return <div>{this.categoryWrappers()}</div>
  }
}

export default CollectionBuilder
