import React from 'react'
import { navigate } from 'gatsby'
import logo from '../assets/rnz.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { window } from 'browser-monads'
import SVG from 'react-inlinesvg'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import plus from '../static/icons/plus.svg'
import minus from '../static/icons/minus.svg'
import xButton from '../static/icons/cross.svg'

import footer from '../css/modules/footer.module.scss'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  render() {
    const linkList = (
      <div>
        <a href="mailto:storytimefeedback@rnz.co.nz">Story feedback</a>
        <span className={footer.divider}>|</span>
        <a href="mailto:webmaster@rnz.co.nz?subject=Storytime%20website%20feedback">
          Website Feedback
        </a>
        <span className={footer.divider}>|</span>
        <a href="https://www.radionz.co.nz/about/digitalprivacypolicy">
          Privacy Policy
        </a>
      </div>
    )

    return (
      <div style={{ display: this.state.loading ? 'none' : 'unset' }}>
        <div className={footer.container}>
          <div className={footer.logoWrapper}>
            <a href="https://www.rnz.co.nz">
              <img alt="RNZ logo" className={footer.logo} src={logo} />
            </a>
          </div>
          <div className={footer.wrapper}>
            <div className={footer.left}>
              <p>Storytime stories are produced by RNZ.</p>
              <p>
                Storytime can also be heard on-air on RNZ National Saturday and
                Sunday mornings 6-7am.
              </p>
              <div className={footer.largeLinks}>{linkList}</div>
            </div>
            <div className={footer.right}>
              <p>
                Sign up to the Storytime newsletter to hear about new stories,
                events and how to make the most of the Storytime collection.
              </p>
              <br />
              <a
                className={footer.newsletterLink}
                href="https://radionz.us6.list-manage.com/subscribe?u=211a938dcf3e634ba2427dde9&id=6a3bdf321f">
                Sign up to the newsletter
              </a>
              <div className={footer.smallLinks}>{linkList}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
